@import url('https://fonts.googleapis.com/css2?family=Bungee+Hairline&display=swap');
.header-timer-container {
    text-align: center;
  }
  
  .header-title {
    color: #fff;
    /* font-size:4rem; */
    font-weight: bolder;
  }
  
  .timer {
    /* font-size: 2rem; */
    font-family: "Bungee Hairline", sans-serif;
    font-weight: bolder;
    color: #fff;
    
  }
